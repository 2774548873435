<template>
  <div>
      <b-row>
        <!-- Field: First Name -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Kode</h5>
            <p>{{ transactionData.transaction_number }}</p>
        </b-col>

        <!-- Field: User -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Pengguna</h5>
            <p>{{ transactionData.user.name }}</p>
        </b-col>

        <!-- Field: Total Harga -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Total Harga</h5>
            <p>{{ formatPrice(transactionData.total_price) }}</p>
        </b-col>

        <!-- Field: Payment Method -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Metode Pembayaran</h5>
            <p>{{ transactionData.payment_method }}</p>
        </b-col>

        <!-- Field: Payment Referal Number -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Referal Number</h5>
            <p>{{ transactionData.payment_referal_number }}</p>
        </b-col>

        <!-- Field: Tanggal Transaksi -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Tgl Transaksi</h5>
            <p>{{ formatDate(transactionData.transaction_date) }}</p>
        </b-col>

        <!-- Field: Status -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Status</h5>
            <p>
                <b-badge :variant="statusVariant(transactionData.transaction_status)">
                    {{ getStatusLabel(transactionData.transaction_status)  }}
                </b-badge>
            </p>
        </b-col>

        <!-- Field: Kode Voucher -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Kode Voucher</h5>
            <p>{{ (transactionData.voucher_code) ? transactionData.voucher_code : '-' }}</p>
        </b-col>

        <!-- Field: Kode Voucher -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Nilai Voucher</h5>
            <p>{{ (transactionData.voucher_value) ? transactionData.voucher_value : '-' }}</p>
        </b-col>

        <!-- Field: Additional Amount -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Nominal Pembayaran Tambahan</h5>
            <p>{{ (transactionData.additional_payment_amount) ? transactionData.additional_payment_amount : '-' }}</p>
        </b-col>

      </b-row>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BBadge, BImg
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BBadge,
    BImg
  },
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    transactionData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getStatusLabel(status) {
      if(status == 1) {
        return 'Berhasil'
      }
      else
      if(status == 0) {
        return 'Gagal'
      }
      else
      if(status == 2) {
        return 'Menunggu Pembayaran'
      }
      else {
        return ''
      }
    },
    formatDate(value) {
      return moment(String(value), 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    statusVariant(status) {
      return status == 1 ? 'light-success' : 'light-danger'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
