<template>
  <div>
    <component :is="transactionData === undefined ? 'div' : 'b-card'">

      <div v-if="!transactionData" class="text-center">
          <b-spinner label="Tunggu..."></b-spinner>
      </div>

      <div v-if="transactionData">
        <h4>
          <feather-icon
            icon="ShoppingCartIcon"
            size="18"
          /> &nbsp;
          Transaksi
        </h4>
        <transaction-view-content
          :transaction-data="transactionData"
          class="mt-2 pt-75"
        />

          <b-row class="mt-2">
            <b-col>
              <div style="float: right">
                <b-button size="md" variant="outline-primary" @click="back()">
                  <feather-icon
                    icon="ArrowLeftIcon"
                    class="mr-50"
                  /><span>Kembali</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

      </div>

    </component>

    <b-card v-if="transactionData !== undefined">
        <transaction-detail-table :rows="transactionData.details" v-if="transactionData" />
        <div v-else class="text-center">
          <b-spinner label="Tunggu..."></b-spinner>
        </div>
    </b-card>

  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner, BButton, BCol, BRow
} from 'bootstrap-vue'
import router from '@/router'
import TransactionViewContent from './TransactionViewContent.vue'
import TransactionDetailTable from './TransactionDetailTable.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,
    BButton,
    BCol,
    BRow,

    TransactionViewContent,
    TransactionDetailTable,
  },
  data() {
    return {
      transactionData: null,
    }
  },
  methods: {
    back() {
        this.$router.push('/transactions')
    }, 
    fetchData() {
      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/transactions/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.transactionData = {
              id: currentData.id,
              transaction_number: currentData.transaction_number,
              user: currentData.user,
              total_price: currentData.total_price,
              payment_method: currentData.payment_method,
              payment_referal_number: currentData.payment_referal_number,
              transaction_date: currentData.transaction_date,
              transaction_status: currentData.transaction_status,
              voucher_code: currentData.voucher_code,
              voucher_value: currentData.voucher_value,
              additional_payment_amount: currentData.additional_payment_amount,
              details: currentData.details,
            }
          }
      })
    }
  },
  created() {
    this.fetchData()
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>